@charset "UTF-8";
.ng-select.ng-select-multiple .ng-value-container .ng-value {
  font-size: 0.9em;
  height: 20px;
  margin-bottom: 5px;
  background-color: #ebf5ff;
  border-radius: 2px;
  margin-right: 5px;
}
.ng-select.ng-select-multiple .ng-value-container .ng-value .ng-value-icon {
  cursor: pointer;
}
.ng-select.ng-select-multiple .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
}
.ng-select.ng-select-multiple .ng-value-container .ng-value .ng-value-icon,
.ng-select.ng-select-multiple .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ng-select.ng-dropdown-panel,
.ng-select .ng-dropdown-panel {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  background: #fff;
}
.ng-select .ng-option {
  font-size: 14px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 7px 10px;
  position: relative;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ng-select .ng-option:hover {
  background-color: #eaeaea;
  color: #000;
}
.ng-select .ng-option.ng-option-selected {
  background-color: #b3d6f2;
  color: #000;
}
.ng-select .ng-arrow-wrapper {
  color: #212121;
  font-size: 12px;
  position: absolute !important;
  right: 12px;
  bottom: 4px;
  z-index: 1;
  pointer-events: none;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  font-family: FabricMDL2Icons;
  font-style: normal;
  font-weight: 400;
  speak: none;
  color: #212121;
  font-size: 12px;
  z-index: 1;
  pointer-events: none;
  display: inline-block;
  width: 12px !important;
  height: 18px !important;
}
.ng-select .ng-arrow-wrapper .ng-arrow::before {
  content: "";
}
.ng-select .ng-select-container.ng-has-value .ng-placeholder {
  display: none;
}

.ng-dropdown-panel.ng-select-multiple .ng-value-container .ng-value {
  font-size: 0.9em;
  height: 20px;
  margin-bottom: 5px;
  background-color: #ebf5ff;
  border-radius: 2px;
  margin-right: 5px;
}
.ng-dropdown-panel.ng-select-multiple .ng-value-container .ng-value .ng-value-icon {
  cursor: pointer;
}
.ng-dropdown-panel.ng-select-multiple .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
}
.ng-dropdown-panel.ng-select-multiple .ng-value-container .ng-value .ng-value-icon,
.ng-dropdown-panel.ng-select-multiple .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ng-dropdown-panel.ng-dropdown-panel,
.ng-dropdown-panel .ng-dropdown-panel {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  background: #fff;
}
.ng-dropdown-panel .ng-option {
  font-size: 14px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 7px 10px;
  position: relative;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ng-dropdown-panel .ng-option:hover {
  background-color: #eaeaea;
  color: #000;
}
.ng-dropdown-panel .ng-option.ng-option-selected {
  background-color: #b3d6f2;
  color: #000;
}
.ng-dropdown-panel .ng-arrow-wrapper {
  color: #212121;
  font-size: 12px;
  position: absolute !important;
  right: 12px;
  bottom: 4px;
  z-index: 1;
  pointer-events: none;
}
.ng-dropdown-panel .ng-arrow-wrapper .ng-arrow {
  font-family: FabricMDL2Icons;
  font-style: normal;
  font-weight: 400;
  speak: none;
  color: #212121;
  font-size: 12px;
  z-index: 1;
  pointer-events: none;
  display: inline-block;
  width: 12px !important;
  height: 18px !important;
}
.ng-dropdown-panel .ng-arrow-wrapper .ng-arrow::before {
  content: "";
}
.ng-dropdown-panel .ng-select-container.ng-has-value .ng-placeholder {
  display: none;
}

body {
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: "Segoe UI WestEuropean", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ms-Button {
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
  user-select: none;
  background-color: transparent;
  border: transparent;
}
.ms-Button .ms-Icon {
  color: #277DFF;
  font-size: 16px;
  margin-top: 0px;
  margin-right: 4px;
  margin-bottom: 0px;
  margin-left: 4px;
  height: 16px;
  text-align: center;
  flex-shrink: 0;
  line-height: 16px;
}
.ms-Button:hover {
  background-color: #eaeaea;
  border-color: #eaeaea;
}

.ms-OverflowSet-item {
  overflow: hidden;
  height: 40px;
}

.ms-CommandBarItem-link {
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
  min-width: 40px;
}

.flex-container {
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.menu-Icon {
  font-size: 12px;
  margin-top: 0px;
  margin-right: 4px;
  margin-bottom: 0px;
  margin-left: 4px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  flex-shrink: 0;
  color: #7688a3;
}

.dropdown-menu {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  min-width: 180px;
  max-width: 220px;
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.108), 0px 3.2px 7.2px rgba(0, 0, 0, 0.132);
  border-radius: 0px 0px 2px 2px;
  display: none;
}

a.ms-ContextualMenu-link {
  text-decoration: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .dropdown-menu {
    box-shadow: none;
  }
}
.ms-Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.bottom-right,
.button-bar-right {
  padding: 10px;
}

.bottom,
.bottom-left,
.button-bar {
  padding: 10px;
}

.bottom-left,
.button-bar {
  bottom: 0;
  position: absolute;
}

.bottom-right,
.button-bar-right {
  bottom: 0;
  position: absolute;
  right: 0;
}

.block {
  display: block;
}

.block + .block {
  margin-top: 16px;
}

.sidebar {
  padding: 12px;
}

.ms-TextField-errorMessage {
  font-size: 12px;
  font-weight: 400;
  color: #a80000;
  margin: 0;
  padding-top: 5px;
}

.ms-TextField-invalid {
  border-color: #a80000;
}

.ms-Dropdown-item.is-hidden {
  display: none;
  visibility: hidden;
}

.ms-CheckBox-field:before {
  border: 1px solid #a6a6a6;
}

.ms-CheckBox-field.is-indeterminate:after {
  display: block;
}

.ms-CheckBox-field.is-indeterminate:before {
  border: 1px solid #0078d7;
  background-color: transparent;
}

.is-indeterminate.ms-CheckBox-field:after {
  content: " ";
  width: 10px;
  height: 10px;
  background: #0078d7;
  top: 5px;
  left: 5px;
}

ul {
  margin-bottom: 0;
}

.modal-content {
  border: none;
  border-radius: 0;
}

.ms-Dropdown-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #B3B0AD;
  border-radius: 2px;
}

.ms-TextField-field {
  border: 1px solid #B3B0AD;
  border-radius: 2px;
}

.ng-select-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: 1px solid #B3B0AD;
  border-radius: 2px;
  font-weight: 300;
  font-size: 14px;
  color: #323130;
  min-height: 32px;
  padding: 5px 35px 0 10px;
  width: 100%;
  min-width: 96px;
  outline: 0;
  text-overflow: ellipsis;
}
.ng-select-container .ng-value {
  width: 100%;
}

.ng-value-container .ng-input > input {
  white-space: nowrap;
  overflow: hidden;
}

.search-wrapper {
  position: relative;
}
.search-wrapper .ms-Icon--Search {
  position: absolute;
  top: 10px;
  left: 12px;
  font-size: 16px;
  color: #277DFF;
}
.search-wrapper .ms-Button {
  position: absolute;
  top: 6px;
  right: 4px;
  color: #277DFF;
  min-width: 48px;
}
.search-wrapper .ms-TextField-field {
  height: 44px;
  padding: 13px 48px 13px 48px;
  border: none;
  border-bottom: 1px solid #E1DFDD;
}
.search-wrapper .ms-TextField-field:focus {
  border-color: #277DFF;
}

.ms-DatePicker-holder {
  right: 0;
}

.ms-Button.is-disabled,
.ms-Button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.toast {
  position: absolute;
  z-index: 1021;
  bottom: 0;
  left: 50%;
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
  max-width: 50%;
  min-width: 120px;
}
.toast .ms-MessageBar-content {
  display: flex;
  align-items: flex-start;
}
.toast .ms-MessageBar-content .ms-MessageBar-text {
  line-height: 24px;
  padding: 0 8px;
}
.toast .ms-MessageBar-icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toast .ms-MessageBar-icon .ms-Icon--Clear {
  font-size: 8px;
  height: 8px;
  line-height: 10px;
  color: #323130;
  cursor: pointer;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .toast {
    transition: none;
  }
}
@media (min-width: 1024px) and (max-width: 1366.97px) {
  .ng-select-container {
    min-width: unset;
    padding-right: 20px;
  }

  .ng-select .ng-arrow-wrapper {
    right: 6px;
  }
}
.ms-ContextualMenu-item--header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-sub-menu {
  max-width: 200px;
  min-width: 200px;
  transform: translateX(-200px) !important;
}

.icon-middle {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  vertical-align: middle;
  background-size: cover;
}