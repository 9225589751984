// Icon class

@mixin icon {
    color: #277DFF;
    font-size: 16px;
    margin-top: 0px;
    margin-right: 4px;
    margin-bottom: 0px;
    margin-left: 4px;
    height: 16px;
    text-align: center;
    flex-shrink: 0;
  }