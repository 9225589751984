@mixin ng-select() {

    &.ng-select-multiple {

        .ng-value-container {

            .ng-value {
                font-size: .9em;
                height: 20px;
                margin-bottom: 5px;
                background-color: #ebf5ff;
                border-radius: 2px;
                margin-right: 5px;

                .ng-value-icon {
                    cursor: pointer;

                    &.left {
                        border-right: 1px solid #b8dbff;
                    }
                }

                .ng-value-icon,
                .ng-value-label {
                    display: inline-block;
                    padding: 1px 5px;
                    max-width: 90px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }
        }
    }

    &.ng-dropdown-panel,
    .ng-dropdown-panel {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .4);
        background: #fff;
    }

    .ng-option {
        font-size: 14px;
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        padding: 7px 10px;
        position: relative;
        border: 1px solid transparent;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            background-color: #eaeaea;
            color: #000;
        }

        &.ng-option-selected {
            background-color: #b3d6f2;
            color: #000;
        }
    }

    .ng-arrow-wrapper {
        color: #212121;
        font-size: 12px;
        position: absolute !important;
        right: 12px;
        bottom: 4px;
        z-index: 1;
        pointer-events: none;

        .ng-arrow {
            font-family: FabricMDL2Icons;
            font-style: normal;
            font-weight: 400;
            speak: none;
            color: #212121;
            font-size: 12px;
            z-index: 1;
            pointer-events: none;
            display: inline-block;
            width: 12px !important;
            height: 18px !important;

            &::before {
                content: '\E70D';
            }
        }
    }

    .ng-select-container {

        &.ng-has-value .ng-placeholder {
            display: none;
        }
    }

}

.ng-select {
    @include ng-select();
}

.ng-dropdown-panel {
    @include ng-select();
}